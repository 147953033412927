import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "../../../core/app.store";
import * as newsActions from "../../../redux/store/news/news.store";

import {
  APIUrlDefault,
  getUserInfo,
} from "../../../utils/configuration";
import dateformat from "dateformat";
import "./news.scss";

function HomeNewsView(props) {
  const { showLoading } = props;

  const [newsModel, setNewsModel] = useState([]);
  const [hotNews, setHotNews] = useState({});
  const [pagingNews, setPagingNews] = useState();
  const [currentPage, setCurrentPage] = useState();
  const pageSize = 4;
  const categoryId = 1;

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetListNews({
        categoryId: categoryId,
        pageIndex: 1,
        pageSize: pageSize
      }),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetListNews = (data) => {
    return new Promise((resolve, reject) => {
      newsActions.GetListNews(data).then(
        (res) => {
          setNewsModel(
            res &&
              res.content &&
              res.content.items
              && res.content.items.length > 0 ? res.content.items : []
          );
          setPagingNews(res.content);
          setCurrentPage(res.content.pageIndex);
          setHotNews(res.content.items[0]);

          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  const getNextPage = () => {
    let nextPage = 1;
    if (currentPage > 0) {
      nextPage = currentPage + 1;
    }
    onGetListNews({
      categoryId: categoryId,
      pageIndex: nextPage,
      pageSize: pageSize
    })
  }

  const getPreviousPage = () => {
    let prePage = 1;
    if (currentPage > 1) {
      prePage = currentPage - 1;
    }
    onGetListNews({
      categoryId: categoryId,
      pageIndex: prePage,
      pageSize: pageSize
    })
  }

  return (
    <div>
      <section className="news">
        <div className="container">
          <div className="title">
            <div className="row">
              <div className="col">
                <h3>Tin tức</h3>
              </div>
              <div className="col">
                <div className="new-nav">
                  {
                    pagingNews && pagingNews.hasPreviousPage && (
                      <a className="nav-action" onClick={getPreviousPage}>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0)">
                            <path
                              d="M2.39225 5.35688L6.8892 9.85234C7.08652 10.0492 7.40622 10.0492 7.60404 9.85234C7.80137 9.65551 7.80137 9.33582 7.60404 9.13899L3.46378 5.00023L7.60354 0.861463C7.80087 0.664638 7.80087 0.344942 7.60354 0.147619C7.40622 -0.0492063 7.08603 -0.0492063 6.8887 0.147619L2.39175 4.64304C2.19746 4.83783 2.19746 5.16255 2.39225 5.35688Z"
                              fill="#333333" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="10" height="10" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    )
                  }
                  {pagingNews && pagingNews.hasNextPage && (
                    <a className="nav-action" onClick={getNextPage}>
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                          <path
                            d="M7.60775 4.64312L3.1108 0.147661C2.91348 -0.0491642 2.59378 -0.0491643 2.39596 0.147661C2.19863 0.344486 2.19863 0.664181 2.39596 0.861006L6.53622 4.99977L2.39646 9.13854C2.19913 9.33536 2.19913 9.65506 2.39646 9.85238C2.59378 10.0492 2.91397 10.0492 3.1113 9.85238L7.60825 5.35696C7.80254 5.16217 7.80254 4.83745 7.60775 4.64312Z"
                            fill="#333333" />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="10" height="10" fill="white" transform="translate(10 10) rotate(-180)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              {hotNews && hotNews.id > 0 ? (
                <div id="hot-new" className="hot-new">
                  <div className="new-image">
                    <Link to={"/tin-tuc-chi-tiet/" + hotNews.id}>
                      <img
                        src={APIUrlDefault + hotNews.image_Url}
                        onError={(e) =>
                          (e.target.src = require("../../../assets/image/no_image.png"))
                        }
                        alt={hotNews.title}
                      />
                    </Link>
                  </div>
                  <a href={"/tin-tuc-chi-tiet/" + hotNews.id} className="new-title">{hotNews.title}</a>
                  <div className="new-date">{dateformat(hotNews.created_date, "dd/mm/yyyy")}</div>
                  <p className="new-desc" dangerouslySetInnerHTML={{ __html: hotNews.description }}></p>
                </div>
              ) : (
                <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
              )}
            </div>
            <div className="col-lg-6 col-12">
              <div id="home-other-news" className="other-news">
                {newsModel && newsModel.length > 0 ? (
                  newsModel.slice(1).map((item, index) => (
                    <div key={index} className="new-item">
                      <div className="new-image">
                        <Link to={"/tin-tuc-chi-tiet/" + item.id}>
                          <img
                            src={APIUrlDefault + item.image_Url}
                            onError={(e) =>
                              (e.target.src = require("../../../assets/image/no_image.png"))
                            }
                            alt={item.title}
                          />
                        </Link>
                      </div>
                      <div className="new-info">
                        <Link to={"/tin-tuc-chi-tiet/" + item.id} className="new-title">{item.title}</Link>
                        {/* <Link to={"/tin-tuc-chi-tiet/" + item.id} className="view-detail">
                              Xem thêm
                              <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.842842 7.83347L16.029 7.83355L12.0238 11.6689C11.7037 11.9754 11.701 12.4812 12.0178 12.7911C12.3347 13.1009 12.8491 13.1036 13.1692 12.7971L18.5779 7.61782C18.898 7.3113 18.9007 6.81345 18.5838 6.50358L13.2382 1.25963C12.9214 0.94976 12.4069 0.947047 12.0869 1.25356C11.7668 1.56008 11.7641 2.05793 12.081 2.3678L16.0373 6.25307L0.851179 6.25298C0.40206 6.25061 0.0327228 6.60428 0.0304302 7.03892C0.0281376 7.47355 0.393723 7.8311 0.842842 7.83347Z"
                                    fill="#2049EF"/>
                              </svg>
                            </Link> */}
                        <div className="new-date" style={{ fontSize: '14px' }}>{dateformat(item.created_date, "dd/mm/yyyy")}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
                )}

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  listPolygon: state.mapData.listPolygon
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(HomeNewsView));
