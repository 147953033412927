import React, { useState, useEffect } from 'react';
import './planning-type.scss';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import apiConfig from '../../../../api/api-config';

import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import FileViewer from "../../../../components/file-viewer/components/file-viewer";
import * as statementActions from '../../../../redux/store/statement/statement.store';
import * as config from '../../../../utils/configuration';
import * as appActions from "../../../../core/app.store";
import * as homePageStore from "../../../../redux/store/home-page/home-page.store";
import PageUrls from '../../../../common/url-collect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Slider from "react-slick";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import * as viVN from "../../../../languages/vi-VN.json";
import $ from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
        <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

function PlanningIntroduceView(props) {
    const { isLoading, showLoading, params, setDataSelectBoxSearch, selected } = props;
    const settingSlider = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const settingSlider1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [planningDetail, setPlanningDetail] = useState();
    const [data, setData] = useState({});
    const [slideShow, setSlideShow] = useState([]);
    const [isShowFile, setIsShowFile] = useState(true);
    const [isShowPreview, setShowPreview] = useState(false);
    const [file, setFile] = useState();
    const GetDetailPlannningType = (id) => {
        showLoading(true);
        return homePageStore.GetDetailPlannningType(id).then(res => {
            setData(res && res.content ? res.content : {})
            showLoading(false)
        }).catch(err => showLoading(false))
    }
    useEffect(() => {
        const key = new URLSearchParams(params.location.search).get("key");
        const search = new URLSearchParams(params.location.search).get("search");
        key && !search && GetDetailPlannningType(key);
    }, [params.location.search])

    useEffect(() => {
        selected && GetDetailPlannningType(selected.id);
    }, [selected])

    const embedHandle = (link) => {
        return link.slice(-11);
    }

    const onShowFilePreview = (file) => {
        if (file) {
            setShowPreview(true);
            setFile(file);
            // $("html, body").animate({ scrollTop: 0 }, 500);
        }
    };

    const renderFilePreview = (file) => {
        console.log(file.fileType)
        let isSupport = config.FilePreviewSupport(file.fileType.slice(1));
        if (isSupport)
        return (
            <a
                onClick={() => {onShowFilePreview(file)}}
                className=" mr-3 cursor-pointer"
            >
                <FontAwesomeIcon icon={faEye} className="mr-1" /> Xem
            </a>
        );
    };
    return (
        <div className="planning-introduce_container position-relative">
            {data.name ? (
                <>
                    <div className="planning_banner">
                        <div className='planning_img_banner'>
                            <img src={apiConfig.api + data?.image_Url} alt="data.name" />
                        </div>
                        <div className="planning_introduce_block">
                            <div className="planning_introduce_name">
                                <div className="introduce_name_">
                                    <p>{data.name}</p>
                                </div>
                                <div className="link_to_map">
                                    <a href={`${apiConfig.domainUserSide}/ban-do-quy-hoach/${data.mapId}`}>Xem bản đồ &#10132;</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="indexing">
                        {data.event && (<><a href="#planning_event">Sự kiện quan trọng</a><span> / </span></>)}
                        <a href="#planning_infrastructure">Cơ sở hạ tầng</a><span> / </span>
                        <a href="#planning_introduce_content">Giới thiệu</a><span> / </span>
                        <a href="#file_video">Tài liệu & Video</a>
                    </div>
                    <div className="row planning_introduce_info">
                        <div className="col-sm-3 planning_introduce_item planning_area">
                            <h5>{data?.totalArea} m&#178;</h5>
                            <h6>Tổng diện tích đất</h6>
                        </div>
                        <div className="col-sm-3 planning_introduce_item">
                            <img src={require('../../../../assets/icon/map-icon.png')} alt="" />
                            <h6>Diện tích có sẵn cho thuê</h6>
                            <p>{data?.availableRentArea} m&#178;</p>
                        </div>
                        <div className="col-sm-3 planning_introduce_item">
                            <img src={require('../../../../assets/icon/factory-icon.png')} alt="" />
                            <h6>Tỉ lệ lấp đầy</h6>
                            <p>{data?.fillRate} m&#178;</p>
                        </div>
                        <div className="col-sm-3 planning_introduce_item">
                            <img src={require('../../../../assets/icon/hotel-icon.png')} alt="" />
                            <h6>TIỆN ÍCH</h6>
                        </div>
                    </div>
                    {/* Sự kiện quan trọng */}
                    {data.event && (
                        <div id="planning_event" className="planning_event">
                            <div className="planning_event_tile">
                                <p>SỰ KIỆN QUAN TRỌNG</p>
                            </div>
                            <div className="event_slide">
                                <Slider {...settingSlider1}>
                                    {data.event?.map((item, index) => (
                                        <div key={index} className="event_slide_item">
                                            <div className="event_date_item">
                                                <p>{item.date}</p>
                                                <div className="event_date_dote"></div>
                                            </div>
                                            <img src={apiConfig.api + item.avatar.filePath} alt="" />
                                            <div className="event_description_item">
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>

                        </div>
                    )}
                    {/* Cơ sở  hạ tầng */}
                    <div id='planning_infrastructure' className='planning_infrastructure'>
                        <div className="planning_infrastructure_title">
                            <p>CƠ SỞ HẠ TẦNG</p>
                        </div>
                        <div className='planning_slide'>
                            <Slider {...settingSlider}>
                                <div className="home-map">
                                    <img src={apiConfig.api + data?.image_Url} alt="slide" />
                                </div>
                                {data.imageFiles?.map(
                                    (item, index) =>
                                        <div className="home-map" key={index}>
                                            <img src={apiConfig.api + item.filePreview} alt="slide" />
                                        </div>
                                )
                                }
                            </Slider>
                        </div>
                        <div className="row infrastructure_content">
                            {/* <div className="col-2dot4">
                                <img src={require('../../../../assets/icon/cctv-icon.png')} alt="" />
                                <h6>QUẢN LÝ BẤT ĐỘNG SẢN VÀ AN NINH CHUNG</h6>
                                <p>tại chỗ 24/7</p>
                            </div> */}
                            <div className="col-sm-4">
                                <img src={require('../../../../assets/icon/electric-icon.png')} alt="" />
                                <h6>ĐIỆN</h6>
                                <p>{data?.powerSystem}</p>
                            </div>
                            <div className="col-sm-4">
                                <img src={require('../../../../assets/icon/water-icon.png')} alt="" />
                                <h6>NƯỚC</h6>
                                <p>{data?.waterSystem}</p>
                            </div>
                            <div className="col-sm-4">
                                <img src={require('../../../../assets/icon/water-handle-icon.png')} alt="" />
                                <h6>TRẠM XỬ LÝ NƯỚC THẢI</h6>
                                <p>{data?.wastewaterTreatmentSystem}</p>
                            </div>
                            {/* <div className="col-2dot4">
                                <img src={require('../../../../assets/icon/telecommunication-icon.png')} alt="" />
                                <h6>VIỄN THÔNG</h6>
                                <p>1,200 đường dây</p>
                            </div> */}
                        </div>
                    </div>
                    {/* bài giới thiệu */}
                    <div id="planning_introduce_content" className="planning_introduce_content">
                        <div className="introduce_content_title">
                            <p>GIỚI THIỆU</p>
                        </div>
                        <div className="description">
                            <p>{data.description}</p>
                        </div>
                        <div className='introduce_content'>
                            <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
                        </div>
                    </div>
                    {/* file and video */}
                    <div id='file_video' className="file_video">
                        <div className="file_video_title">
                            <div
                                className={`is_file ${isShowFile ? 'is_active' : ''}`}
                                onClick={() => setIsShowFile(true)}
                            >
                                <p>TÀI LIỆU</p>
                            </div>
                            <div
                                className={`is_video ${!isShowFile ? 'is_active' : ''}`}
                                onClick={() => setIsShowFile(false)}
                            >
                                <p>VIDEO</p>
                            </div>
                        </div>
                        <div className="row file_video_content">
                            {isShowFile && (
                                <>
                                    {data.documentsFiles && data.documentsFiles.length > 0 ? (
                                        <>
                                            {data.documentsFiles.map((item, index) => (
                                                <div className='col-sm-3 file_item'>
                                                    <img
                                                        src={require('../../../../assets/icon/file-img.png')}
                                                        alt={item.fileName}
                                                    />
                                                    <p>{item.fileName}</p>
                                                    <div className="row file_item_dl">
                                                        {renderFilePreview(item)}
                                                        <a
                                                            href={apiConfig.api + item.filePreview}
                                                            download={item.fileName}
                                                            target='_blank'
                                                        >
                                                            <FontAwesomeIcon icon={faDownload} /> Tải về
                                                        </a>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <>Chưa có tài liệu</>
                                    )}
                                </>
                            )}
                            {!isShowFile && (
                                <>
                                    {(data.video1 || data.video2 || data.video3) ? (
                                        <div className="video_youtube row">
                                            {data.video1 && (
                                                <div className='col-sm-4'>
                                                    <YoutubeEmbed embedId={embedHandle(data.video1)} />
                                                </div>
                                            )}
                                            {data.video2 && (
                                                <div className='col-sm-4'>
                                                    <YoutubeEmbed embedId={embedHandle(data.video2)} />
                                                </div>
                                            )}{data.video3 && (
                                                <div className='col-sm-4'>
                                                    <YoutubeEmbed embedId={embedHandle(data.video3)} />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <>Chưa có Video</>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div>
                    <p style={{ color: 'red' }}>Chưa có thông tin</p>
                </div>
            )


            }
            <Dialog
                fullWidth
                maxWidth="lg"
                open={isShowPreview}
                onClose={() => setShowPreview(false)}
            //TransitionComponent={Transition}
            >
                <DialogTitle>{file?.fileName}</DialogTitle>
                <DialogContent>
                    {file ? (
                        <FileViewer
                            key={file.fileId}
                            fileType={file.fileType.slice(1)}
                            filePath={config.APIUrlDefault + file.filePreview}
                            errorComponent={
                                <div className="mt-4 d-flex align-items-center justify-content-center">
                                    <CancelPresentationIcon className="text-danger mr-1" /> Lỗi
                                    đọc file
                                </div>
                            }
                            onError={() => console.log("err")}
                        />
                    ) : (
                        <div className="mt-4 d-flex align-items-center justify-content-center">
                            <CancelPresentationIcon className="text-danger mr-1" /> Không có
                            file để hiển thị
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}
const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(PlanningIntroduceView);