import React from "react";
export default function BannerView(props) {
    return (
        <header className="header">
            <div className="container header-banner" id="banner">
                <div className="row">
                    <div className="col-md-6">
                        <div className="logo d-flex">
                            <a href="/">
                                <img
                                    className="logo"
                                    src="../logo-footer.png" 
                                    style={{ width: '100px' }}
                                    alt="Logo"
                                />
                            </a>
                            <div className="header-title"></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
