const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
      api: "https://canthoip-api.cgis.asia/",
      domainUserSide: "http://localhost:3000",
      domainAdminSide: "http://localhost:3000",
      domainName: "canthoip.cgis.asia",
      doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
      doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/wms",
  },
  production: {
      api: "https://canthoip-api.cgis.asia/",
      domainUserSide: "https://canthoip.cgis.asia",
      domainAdminSide: "https://admin.canthoip.cgis.asia",
      domainName: "canthoip.cgis.asia",
      doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
      doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/wms",
  },
};

module.exports = apiEnvironment[env];
