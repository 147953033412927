import React, { useEffect, useState } from "react";
import "./left-menu.scss";
import UrlCollect from "../../../common/url-collect";
import { Link } from "react-router-dom";
import SearchPlanning from "../../../components/search-planning/search-planning.view"
import SearchPlanningView from "../search-planning-general/search-planning-name.view";
import SearchByMyPosition from "../../look-up-planning/child-modules/search-by-position/search-by-position.view";
import SearchByAddress from "../../look-up-planning/child-modules/search-address/search-address.view";
import { connect } from "react-redux";
import PlanningCategoriesModel from "../../../models/map-data-model-b/planning-categories-model";
import OpenlayerController from "../../../models/open-layer/openlayer-controller-custom.model";
import history from "../../../common/history";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import * as mapHelper from "../../../common/map";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../../utils/configuration";

interface InitLeftMenuViewProps {
  isDisplaySearchResult: boolean;
  resetSearchDisplayMode: Function;
  listPlanningCategoriesAll?: PlanningCategoriesModel[];
  openLayerController?: OpenlayerController;
  planningType: any;
  listPolygon: any;
  dataTabInfomation: any;
  showInfo?: boolean;
  setShowInfo?: any;
}

function LeftMenuView(props: InitLeftMenuViewProps) {
  const { dataTabInfomation, showInfo, setShowInfo } = props;
  const [tabInfoModel, setTabInfoModel] = useState();
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (dataTabInfomation) {
      let data = Object.entries(dataTabInfomation)
      let res: any = [];
      data.map((item) => {
        if (item[1] && item[0] != 'style') {
          item[0] == 'loaidat' && res.push(['Loại đất', item[1]])
          item[0] == 'dientich' && res.push(['Diện tích', item[1]])
        }
      });
      setTabInfoModel(res);
      setShowInfo(true);
    }
  }, [dataTabInfomation])

  const getDataRow = (data: any) => {
    if (data && data.length > 0) {
      return (
        data.map((item: any, index: number) => (
          <tr key={index}>
            <td className="row-label" width="50%">{item[0]}</td>
            <td width="50%">{item[1]}</td>
          </tr>
        ))
      )
    } else {
      return (
        <tr>
          <td> Chưa có thông tin</td>
        </tr>
      )
    }

  }

  let commentInput: any = null;

  function handleResetDisplaySearchMode() {
    if (props.isDisplaySearchResult) props.resetSearchDisplayMode();
  }

  function handleSearchCoordinate(event: any) {
    event.preventDefault();
    props.openLayerController?.handleSearchCoordinate(
      commentInput && commentInput.value ? commentInput.value.trim() : ""
    );
  }
  function checkAddressInPolyon(x: any, y: any) {
    let isInside = false;
    props.listPolygon &&
      props.listPolygon.map((itemPolygon: any) => {
        if (
          mapHelper.isPointInPolygon(y, x, itemPolygon)
        ) {
          isInside = true;
          return;
        }
      });
    if (isInside) {
      history.push(
        `${UrlCollect.SyntheticPlanning}?lng=${x}&lat=${y}&searchType=place`
      )
      return true;
    } else {
      ShowNotification(
        viVN.Errors.CoordinateOutSidePolygon,
        NotificationMessageType.Warning,
        NotificationPosition.Center
      );
    }
    return false;
  }

  useEffect(() => {
    const sideBarElement = document.getElementsByClassName(
      "ishow_sidebar"
    )[0] as HTMLElement;
    const tabInfoElement = document.getElementsByClassName(
      "tab_info"
    )[0] as HTMLElement;
    if (sideBarElement) {
      if (showInfo) {
        tabInfoElement.style.left = "0";
        sideBarElement.style.left = "-320px";
      } else {
        sideBarElement.style.left = "0";
        tabInfoElement.style.left = "-375px";
      }
    }
  }, [showInfo])

  return (
    <div className="left-menu-custom-container h-100 position-relative">
      <div className="ishow_sidebar">
          <div className="search-groups p-2">
            <SearchPlanning />
          </div>
          <div
            className={`title-container ${props.isDisplaySearchResult ? "" : "p-2 disabled"
              }`}
            onClick={() => handleResetDisplaySearchMode()}
          >
            Tổng hợp khu công nghiệp
          </div>

          {props.planningType.map((item: any) => (
            <RenderGroupPlanning
              isDisplaySearchResult={props.isDisplaySearchResult}
              titleGroup={item.name}
              key={item.id}
              urlGroup={UrlCollect.PlanningMap + "?key=" + item.id}
              listLayers={item.plannings}
            />
          ))}
        </div>
        <div className="tab_info">
          {tabInfoModel ? (
            <>
              <p>Thông tin quy hoạch</p>
              <div className="info-table">
                <table>
                  <thead>
                    <th className="row-label" style={{ width: "50%" }}>Tên trường</th>
                    <th style={{ width: "50%" }}>Giá trị</th>
                  </thead>
                  <tbody>
                    {getDataRow(tabInfoModel)}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              Chọn đối tượng để lấy thông tin chi tiết.
            </>
          )}
        </div>
      {/* {!showInfo ? (
        <div className="ishow_sidebar">
          <div className="search-groups p-2">
            <SearchPlanning />
          </div>
          <div
            className={`title-container ${props.isDisplaySearchResult ? "" : "p-2 disabled"
              }`}
            onClick={() => handleResetDisplaySearchMode()}
          >
            Tổng hợp khu công nghiệp
          </div>

          {props.planningType.map((item: any) => (
            <RenderGroupPlanning
              isDisplaySearchResult={props.isDisplaySearchResult}
              titleGroup={item.name}
              key={item.id}
              urlGroup={UrlCollect.PlanningMap + "?key=" + item.id}
              listLayers={item.plannings}
            />
          ))}
        </div>
      ) : (
        <div className="tab_info">
          {tabInfoModel ? (
            <>
              <p>Thông tin quy hoạch</p>
              <div className="info-table">
                <table>
                  <thead>
                    <th className="row-label" style={{ width: "50%" }}>Tên trường</th>
                    <th style={{ width: "50%" }}>Giá trị</th>
                  </thead>
                  <tbody>
                    {getDataRow(tabInfoModel)}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              Chọn đối tượng để lấy thông tin chi tiết.
            </>
          )}
        </div>
      )} */}

    </div>
  );
}

interface InitRenderGroupPlanningProps {
  titleGroup: string;
  listLayers: any[];
  urlGroup: string;
  isDisplaySearchResult: boolean;
}

function RenderGroupPlanning(props: InitRenderGroupPlanningProps) {
  return (
    <div className="render-group-planning">
      <Link to={props.urlGroup} className="title-container p-2">
        {props.titleGroup}
      </Link>
      {props.isDisplaySearchResult &&
        props.listLayers &&
        props.listLayers.length > 0 && (
          <div className="pl-3 pr-2 pt-2 pb-2">
            {props.listLayers.map((layerObject, index) => (
              <label
                key={index}
                className="text-truncate"
                title={layerObject.name}
              >
                <input type="checkbox" /> {layerObject.name}
              </label>
            ))}
          </div>
        )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
  planningType: state.statement.planningType,
  listPolygon: state.mapData.listPolygon,
});

export default connect(mapStateToProps)(LeftMenuView);
