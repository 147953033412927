import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";

import * as homePageStore from "../../../redux/store/home-page/home-page.store";
import * as appActions from "../../../core/app.store";
import apiConfig from "../../../api/api-config";
import {
    APIUrlDefault, getUserInfo,
} from "../../../utils/configuration";
import UrlCollect from "../../../common/url-collect";
import "./planning.scss";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomePlanningView(props) {
    const { showLoading } = props;
    const isLogin = getUserInfo() ? true : false;
    const [listData, setListData] = useState([])
    const settingSlider = {
        className: "center",
        centerMode: true,
        centerPadding: "40px",
        focusOnSelect: true,
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <Arrow />,
        prevArrow: <Arrow />,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
              }
            }
          ]
    };

    useEffect(() => {
        showLoading(true);
        homePageStore.GetAllPlannningType().then(res => {
            setListData(res && res.content && res.content.length > 0 ? res.content : [])
            showLoading(false)
        }).catch(err => showLoading(false))
    }, [])

    return (
        <section id="home-link-box" className="planning_home">
            <div className="container">
                <div className="planning_title">
                    <p>Các khu công nghiệp</p>
                </div>
                <Slider {...settingSlider}>
                    {listData.map(
                        (item, index) =>
                        (
                            <div className="planning_slide" key={index}>
                                <a  className="planning__slide" href={`/ban-do-quy-hoach?key=${item.id}`} alt={item.name}>
                                    <img className="planning_img" src={apiConfig.api + item.image_Url} alt="slide" />
                                    <div className="planning_content">
                                        <div className="planning__content">
                                            <p className="planning_name">{item.name}</p>
                                            <p className="planning_description">{item.description}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    )}
                </Slider>

                <div className="link__">
                    <a href="/tong-hop-quy-hoach" alt='tổng hợp quy hoạch'><h3>Xem Tổng hợp khu công nghiệp</h3></a>
                </div>
                {/* <div className="row">
                    {
                        listData.length > 0 && (
                            listData.map((item, index) => (
                                <div key={index} className="col-lg-3 col-sm-6 col-md-3 col-xs-12 col-6 planning_item">
                                    <div className="item">
                                        <a href={`/ban-do-quy-hoach?key=${item.id}`} alt={item.name}>
                                            <img className="planning_img" src={apiConfig.api + item.image_Url} alt="" />
                                            <p className="planning_name">{item.name}</p>
                                        </a>
                                        <p className="Planning_description">{item.description}</p>
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div> */}
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(HomePlanningView));

function Arrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#EEEEEE" }}
            onClick={onClick}
        />
    );
}